import * as React from 'react';
import { FileUploader } from 'baseui/file-uploader';
import { useStyletron } from 'baseui';
import {
  LabelMedium,
  LabelSmall,
  ParagraphSmall,
  ParagraphXSmall,
  LabelXSmall
} from 'baseui/typography';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import HelpQuestionMark from './help/questionMarkHelp';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import ImageViewer from './imageViewerCXL';
import SelectIcon from './icons/index';
import RenderIcon from './icons/renderIcon';
import { ArrowDown, ChevronLeft, ChevronRight, Delete, Upload, Show } from "baseui/icon";
import _ from "lodash";
import styled from '@emotion/styled';
import ImageContainer from './common/image/image-container';
import { getFileName, getFilesUrls } from '../util';
import PortalAwareItem from './home-components/portal';

const helpText = "Here you can add an image or screen capture to a video, making your videos look more professional."

const Paragraph = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: black;
  text-align: center;
`;

const AttachmentContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

const AttachmentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid #d0d0d0;
  border-top: 1px solid #d0d0d0;
`;

const ImageGalleryRowContainer = styled.div`
  display: flex; 
  margin-top: 5px; 
  overflow-x: auto; 
  overflow-y: hidden; 
  width: 100%;
`;

const ImageGalleryContainer = styled.div`
  display: flex; 
  flex-wrap: wrap; 
  margin-top: 1rem; 
  width: 440px;
  margin-left: auto;
  margin-right: auto;
`;

export default ({
  title,
  titleHelpText,
  urlData,
  previewUrls,
  thumbUrls,
  downloadUrls,
  isMultiple,
  onDrop,
  onImageDelete = () => { },
  showFullBleedSettings,
  fullBleed,
  onBleedChange,
  imageSize,
  imageSizeGuidelineURL,
  style = {},
  hasCoverTypeSelection,
  coverType = 'photo',
  onCoverTypeChange,
  iconName,
  onIconChange,
  onReorder,
  accept,
  showHint = true,
  name,
  attachmentUrls = [],
  alwaysShowDropZone = false,
  hideTitle = false
}) => {
  if (!previewUrls) {
    previewUrls = [];
  }
  if (typeof previewUrls == 'string') {
    previewUrls = [previewUrls];
  };
  if (!thumbUrls) {
    thumbUrls = previewUrls;
  };
  if (!downloadUrls) {
    downloadUrls = previewUrls;
  }
  if (!urlData) {
    urlData = previewUrls;
  };
  const [maxSizeError, setMaxSizeError] = React.useState('')
  const [css, theme] = useStyletron();
  const [isImageViewerOpen, setIsImageViewerOpen] = React.useState(false);
  const [replaceImage, setReplaceImage] = React.useState(false);
  const getBackgroundPositionsAccordingToBleed = React.useMemo(() => {
    var css = {};
    css.backgroundSize = 'contain';
    return css;
  }, []);
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [previewUrl, setPreviewUrl] = React.useState('');
  const [previewUrlList, setPreviewUrlList] = React.useState([]);
  const onImageViewerClose = () => setIsImageViewerOpen(false);
  const hasManyImages = previewUrls.length > 1;

  React.useEffect(() => {
    setCurrentIndex(0);
    return () => {
      console.log('_________________________Unmounted');
    }
  }, []);

  React.useEffect(() => {
    setPreviewUrl(previewUrls[currentIndex])
  }, [currentIndex, previewUrls]);

  // React.useEffect(() => {
  //   if (!previewUrl) {
  //     setPreviewUrl(thumbUrls[currentIndex])
  //   }

  //   // if (thumbUrls.length <= 0) {
  //   //   setPreviewUrlList('');
  //   // }
  // }, [thumbUrls])

  const gotoLeft = () => {
    let currentIndexCopy = currentIndex;
    if (currentIndex == 0) return;
    setCurrentIndex(--currentIndexCopy);
  }

  const gotoRight = () => {
    let currentIndexCopy = currentIndex;
    if (currentIndex == (previewUrls.length - 1)) return;
    setCurrentIndex(++currentIndexCopy);
  }

  function testImage(URL, imageFound, imageNotFound) {
    var tester = new Image();
    tester.onload = imageFound;
    tester.onerror = imageNotFound;
    tester.src = URL;
  };

  const [isImageType, setIsImageType] = React.useState(true);

  React.useEffect(() => {
    if (previewUrl) {
      testImage(previewUrl, () => {
        setIsImageType(true);
      }, () => {
        setIsImageType(false);
      })
    };
  }, [previewUrl]);

  const [imageGalleryUrls, setUrls] = React.useState([]);

  React.useEffect(() => {
    console.log('urlData', urlData);
    if (!_.isEqual(urlData, previewUrlList)) {
      setPreviewUrlList(urlData);
    }
  }, [urlData])

  React.useEffect(() => {
    if (Array.isArray(thumbUrls) && thumbUrls.length > 0) {
      let tempThumbUrls = thumbUrls;
      if (!_.isEqual(urlData, previewUrlList)) {
        tempThumbUrls = getFilesUrls(previewUrlList, 'small', 'small', 'thumbnail')
      }
      const columnCounts = 5;
      let urls = []
      let temp = [];
      tempThumbUrls.map((url, index) => {
        let existedName;
        imageGalleryUrls.find(ele => {
          let existedEle = ele.find(el1 => {
            let name = getFileName(previewUrlList[index]);
            if (el1.name === name) {
              return el1;
            }
          })
          if (existedEle) {
            existedName = existedEle;
            return existedEle;
          }
        });
        let name = getFileName(previewUrlList[index]);
        if (existedName) {
          temp.push({ url: existedName.url, index, name });
        } else {
          temp.push({ url, index, name });
        }
        if ((index + 1) % columnCounts === 0) {
          urls.push(temp)
          temp = [];
        }
      });
      if (temp.length > 0) urls.push(temp);
      if (!_.isEqual(urls, imageGalleryUrls)) {
        setUrls(urls);
      }
    }
  }, [previewUrlList])

  const onDragEnd = ({ destination, source, ...rest }) => {
    if (destination && source && destination.index !== source.index) {
      onReorder(source, destination);
    }
  }

  const openImagePreview = () => setIsImageViewerOpen(true)

  const renderImageListItem = (dragProvided, snapshot, columnIndex, index, url) => {
    const onClickImageItem = () => { setCurrentIndex(index); }
    let previewUrlIdx = previewUrls && previewUrls.indexOf(previewUrl);
    previewUrlIdx = previewUrlIdx ?? currentIndex;
    const isSelected = previewUrlIdx === index;
    return (
      // <PortalAwareItem
      //   snapshot={snapshot}
      //   child={
      <div
        onClick={onClickImageItem}
        {...dragProvided.dragHandleProps}
        {...dragProvided.draggableProps}
        ref={dragProvided.innerRef}
      >
        <div className={css({ width: '80px', height: '82px', position: 'relative', cursor: 'pointer', marginLeft: columnIndex != 0 ? '10px' : 0, border: isSelected ? '3px solid #7A5AF8' : '3px solid white' })}>
          <ImageContainer
            className={css({
              height: '100%',
              opacity: 1,
              background: 'none',
              minWidth: '73px',
              maxHeight: '80px',
              objectFit: 'contain',
              ...getBackgroundPositionsAccordingToBleed
            })}
            src={isImageType ? url : "https://img.icons8.com/material-rounded/96/000000/file--v1.png"}
            thumb={"https://img.icons8.com/material-rounded/96/000000/file--v1.png"}
            height={'80px'}
            width={'73px'}
            imgStyle={{ objectFit: 'cover' }}
            alt={'gallery-img'}
          />
        </div>
      </div>
      //   }
      // />
    )
  }

  const renderImageList = () => {
    return (
      previewUrls.length > 0 && (
        <ImageGalleryContainer className='image-gallery-container'>
          <DragDropContext
            onDragEnd={onDragEnd}
          >
            {imageGalleryUrls.map((urls, rowIndex) => {
              return (
                <Droppable
                  droppableId={'row' + rowIndex}
                  type={'image-gallery'}
                  isCombineEnabled={false}
                  direction={'horizontal vertical'}
                >
                  {(dropProvided, snapshot) => (
                    <ImageGalleryRowContainer
                      {...dropProvided.droppableProps}
                      {...dropProvided.dragHandleProps}
                      ref={dropProvided.innerRef}
                    >
                      {urls.map(({ url, index, name }, columnIndex) => {
                        return (
                          <Draggable
                            key={name}
                            draggableId={`column_${name}_${index}`}
                            index={index}
                          >
                            {(dragProvided, snapshot) => renderImageListItem(dragProvided, snapshot, columnIndex, index, url)}
                          </Draggable>
                        );
                      })}
                    </ImageGalleryRowContainer>
                  )}
                </Droppable>
              )
            })}
          </DragDropContext>
        </ImageGalleryContainer>
      )
    )
  }


  const helpTextTitle = title === 'Video Cover Photo' ? helpText : titleHelpText;
  console.log('preview Url', previewUrl)
  return (
    <>
      <div
        className={css({
          // padding: '24px',
          background: theme.colors.primaryB,
          // marginTop: '24px',
          ...style,
        })}
      >
        {(title && !hideTitle) && <div style={{ display: 'flex', marginTop: '1.5rem' }}>
          <LabelMedium
            style={{
              marginBottom: '4px',
              fontFamily: 'IBM Plex Sans',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '16px'
            }}
          >
            {title}
          </LabelMedium>
          {
            (helpTextTitle) &&
            <HelpQuestionMark
              text={title === 'Video Cover Photo' ? helpText : titleHelpText}
              embedCss={{ marginTop: '0px', marginLeft: '8px', height: '18px' }}
            ></HelpQuestionMark>
          }

        </div>}

        {hasCoverTypeSelection && <div style={{ marginTop: '8px', marginBottom: '8px' }}>
          <Checkbox
            checked={coverType == 'icon'}
            onChange={e => onCoverTypeChange(e.target.checked ? 'icon' : 'photo')}
            labelPlacement={LABEL_PLACEMENT.right}
          >
            <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Use Icon as a {title}</ParagraphSmall>
          </Checkbox>
        </div>}


        {/* IMAGE COVER TYPE  */}
        {coverType == 'photo' && (<>
          {isImageType && name != 'CSV Card' && showHint === true && <ParagraphSmall style={{ marginTop: '1rem' }}>
            Optimum file resolution for {isImageType ? 'Image' : 'File'} is {imageSize}. <a href={imageSizeGuidelineURL} style={{ color: theme.colors.primaryA }}>Click here</a> for information on design guidelines.
          </ParagraphSmall>}
          {name == 'CSV Card' && <ParagraphSmall style={{ marginTop: '1rem' }}>
            CSV file
          </ParagraphSmall>}
          {(previewUrl) && <div style={{ width: '100%', backgroundColor: "transparent", textAlign: "center", position: "relative" }}>
            {hasManyImages && <div
              onClick={gotoLeft}
              className={css({
                position: 'absolute', top: '50%', transform: 'translateY(-50%)', height: '64px',
                width: '64px', left: '8px', cursor: 'pointer'
              })}>
              <ChevronLeft size={64} color={theme.colors.primaryA} />
            </div>}
            <img
              onClick={() => { setIsImageViewerOpen(isImageType && name !== 'Attachment') }}
              src={isImageType && name !== 'Attachment' ? previewUrl : "https://img.icons8.com/material-rounded/96/000000/file--v1.png"}
              className={css({
                left: '50%',
                height: '100%',
                opacity: 1,
                background: 'none',
                objectFit: 'contain',
                maxHeight: '200px',
                marginTop: showHint === false ? '1rem' : '0px',
                // maxWidth: '70%',
                ...getBackgroundPositionsAccordingToBleed
              })}
            ></img>

            {hasManyImages && <div
              onClick={gotoRight}
              style={{
                position: 'absolute', top: '50%', transform: 'translateY(-50%)', height: '64px',
                width: '64px', right: '16px', cursor: 'pointer'
              }}>
              <ChevronRight size={64} color={theme.colors.primaryA} />
            </div>}
          </div>}
          {name == 'Attachment' && attachmentUrls.length > 0 && (
            <Paragraph>{attachmentUrls[currentIndex] ? attachmentUrls[currentIndex].name : ''}</Paragraph>
          )}
          {previewUrl && <div style={{ marginTop: '16px', marginBottom: '16px', width: '100%', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
            {isImageType && name !== 'Attachment' &&
              <LabelXSmall
                style={{
                  fontWeight: 400,
                  marginRight: "16px",
                  position: 'relative',
                  float: 'left',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '5px',
                  marginBottom: '5px'
                }}
                onClick={() => setIsImageViewerOpen(true)}>
                <Show size={18} color={theme.colors.primaryA} />&nbsp; View
              </LabelXSmall>}
            <LabelXSmall
              style={{
                fontWeight: 400,
                marginRight: "16px",
                position: 'relative',
                float: 'left',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                marginTop: '5px',
                marginBottom: '5px'
              }}
              onClick={() => onImageDelete(currentIndex)}
            >
              <Delete size={18} color={theme.colors.primaryA} />&nbsp;Delete
            </LabelXSmall>
            <LabelXSmall
              style={{
                fontWeight: 400,
                marginRight: "16px",
                position: 'relative',
                float: 'left',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                marginTop: '5px',
                marginBottom: '5px'
              }}
              onClick={() => { console.log('DownloadURL: ', downloadUrls); window.open(downloadUrls[currentIndex]) }}
            ><ArrowDown size={18} color={theme.colors.primaryA} />&nbsp;Download</LabelXSmall>
            <LabelXSmall
              style={{
                fontWeight: 400,
                marginRight: "16px",
                position: 'relative',
                float: 'left',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                marginTop: '5px',
                marginBottom: '5px'
              }}
              onClick={() => setReplaceImage(true)}
            >
              <Upload size={18} color={theme.colors.primaryA} />&nbsp; {previewUrls.length > 0 ? 'Replace' : 'Upload'}
            </LabelXSmall>
          </div>}

          {/* <div>
            <Paragraph>File size must be under 25MB. Note that larger size files will take longer to load on the users application pages.</Paragraph>
          </div> */}
          {previewUrls.length > 0 && isMultiple && name !== 'Attachment' && renderImageList()}

          {attachmentUrls.length > 0 && name == 'Attachment' && (
            <AttachmentContainer>
              {attachmentUrls.map(url => {
                return (
                  <AttachmentItem>
                    <div style={{ width: '80px', height: '80px', position: 'relative', cursor: 'pointer' }}>
                      <img
                        src={"https://img.icons8.com/material-rounded/96/000000/file--v1.png"}
                        className={css({
                          height: '100%',
                          opacity: 1,
                          background: 'none',
                          minWidth: '73px',
                          maxHeight: '80px',
                          ...getBackgroundPositionsAccordingToBleed
                        })}
                      ></img>

                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                      <Paragraph style={{ wordBreak: 'break-all' }}>{url.name}</Paragraph>
                      <Paragraph>{(url.size / 1024).toFixed(2) + 'KB'}</Paragraph>
                    </div>
                  </AttachmentItem>
                )
              })}
            </AttachmentContainer>
          )}
          {(!previewUrl || replaceImage || alwaysShowDropZone) && (
            <div style={{ width: '100%', marginTop: '24px' }}>
              <FileUploader
                overrides={{
                  FileDragAndDrop: {
                    style: props => ({
                      borderLeftColor: '#7A5AF8',
                      borderRightColor: '#7A5AF8',
                      borderTopColor: '#7A5AF8',
                      borderBottomColor: '#7A5AF8',
                      backgroundColor: '#EFF3FE'
                    }),
                  },
                  ContentMessage: {
                    style: props => ({
                      color: '#7A5AF8',
                    }),
                  },
                  ContentSeparator: {
                    style: props => ({
                      color: '#7A5AF8',
                    }),
                  },
                }}
                maxSize={26214400} // max file size 25 MB in Bytes
                errorMessage={maxSizeError}
                onDrop={(acceptedFiles, rejectedFiles) => {
                  if (acceptedFiles && acceptedFiles[0]) {
                    const { name, path, size, type } = acceptedFiles[0];
                    if (size > 26214400) {
                      setMaxSizeError('File size must be under 25MB. Note that larger size files will take longer to load on the users application pages.')
                      return;
                    } else {
                      onDrop(acceptedFiles, rejectedFiles);
                      setReplaceImage(false);
                    }
                  } else {
                    setMaxSizeError('Invalid file type!')
                  }
                }}
                multiple={isMultiple}
                accept={accept || null}
                errorMessage={maxSizeError}
              ></FileUploader>

            </div>
          )}
          {showFullBleedSettings && <><div style={{ display: 'flex' }}>
            <LabelSmall
              style={{
                marginTop: '16px',
                marginBottom: '8px',
              }}
            >
              Full Bleed Settings
            </LabelSmall>
            <HelpQuestionMark
              text={"Display image at full bleed"}
              embedCss={{ marginTop: '10px', marginLeft: '8px' }}
            ></HelpQuestionMark>
          </div>
            <Checkbox
              checked={fullBleed}
              onChange={e => onBleedChange(e.target.checked)}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <ParagraphSmall style={{ marginTop: '2px', marginBottom: '0px' }}>Display {title} at Full-Bleed</ParagraphSmall>
            </Checkbox>
          </>}
        </>)
        }
        {/* END ----- IMAGE COVER TYPE  */}

        {/* ICON COVER TYPE  */}
        {coverType == 'icon' && <>
          {iconName &&
            <div style={{ width: '100%', height: '150px', backgroundColor: "transparent", textAlign: "center", position: "relative", overflow: "hidden" }}>
              <RenderIcon iconName={iconName}></RenderIcon>
            </div>}
          <SelectIcon value={iconName} onChange={(value) => {
            onIconChange(value);
          }}></SelectIcon>
        </>}
      </div>
      {isImageViewerOpen && <ImageViewer src={previewUrl} theme={theme} isOpen={isImageViewerOpen} gotoRight={hasManyImages ? gotoRight : null} gotoLeft={hasManyImages ? gotoLeft : null} onClose={onImageViewerClose}></ImageViewer>}
    </>
  )
}