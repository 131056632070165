import * as React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
} from 'baseui/modal';
import { trim, startCase, replace } from 'lodash';
import * as FontAwesome from 'react-icons/fa';
import ListOfNativeIcons from './list';
import AddContentButton from '../addContentButton';
import styled from '@emotion/styled';
import { css } from '@emotion/react'
import { useStyletron } from 'baseui';
import { Button } from 'baseui/button';

import HelpQuestionMark from '../help/questionMarkHelp';
import { Plus } from 'baseui/icon';

/** Converts an array of strings from react-native-vector-icons
 *  style to an array of strings corresponding to react-icons/fa style.
 * (e.g. converts 'exclamation-triangle' to 'FaExclamationTriangle')
 */
const LIST_OF_CONVERTED_ICONS = ListOfNativeIcons.map(iconName => {
  const trimmedName = `Fa${trim(startCase(iconName))}`;
  const convertedName = replace(trimmedName, /\s/g, '');
  return convertedName;
});

const renderIcon = (convertedIconName) => {
  const OrgIcon = FontAwesome[convertedIconName];
  return <OrgIcon />;
};

const IconContainer = styled.div`
  align-items: center;
  background-color: rgba(31, 31, 40, 0.2);
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  font-size: 36px;
  justify-content: center;
  margin-bottom: 3px;
  max-width: 100%;
  height: 70px;
  width: 70px;
  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: #ffffff;
      color: #1f1f28;
    `}
  :hover {
    background-color: #42414a;
    cursor: pointer;
  };
`;

const OuterIconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 574px;
  justify-content: space-between;
  overflow: auto;
  width: 100%;
`;

export default ({ value, onChange }) => {
  const [selected, setSelected] = React.useState(value);
  const [isOpen, setIsOpen] = React.useState(false);
  const [css, theme] = useStyletron();

  return (
    <div style={{ marginTop: '16px' }}>
      {/* <AddContentButton
        buttonText={'Select Icon'}
        helpText={"select icon for card"}
        inputPlaceholder={'Add New Category'}
        inputValue={selected}
        setInputValue={setSelected}
        buttonClickOverride={() => setIsOpen(true)}
        style={{
          width: '100%',
          height: '128px',
          backgroundColor: theme.colors.primary50,
          border: '1px dashed #7A5AF8'
        }}
      /> */}
      <Button
        className={css({
          ':hover': {
            backgroundColor: theme.colors.primary50,
          },
        })}
        onClick={() => setIsOpen(true)}
        overrides={{
          BaseButton: {
            style: ({ $theme }) => ({
              backgroundColor: '#5D64AB',
              color: theme.colors.primaryA,
              borderRadius: '4px',
              fontSize: '14px',
              padding: '10px 14px',
              width: '100%',
              height: '128px',
              backgroundColor: theme.colors.primary50,
              border: '1px dashed #7A5AF8'
            }),
          },
        }}
      >
        Select Icon
      </Button>
      <Modal
        overrides={{
          Root: {
            style: {
              zIndex: 3,
            }
          },
          Backdrop: {
            style: ({ $theme }) => ({
              backdropFilter: $theme.modalBackDropFilter,
            }),
          }
        }}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <ModalHeader>{"Choose Icon"}</ModalHeader>
        <ModalBody>
          <OuterIconsContainer>
            {LIST_OF_CONVERTED_ICONS.map((iconName, idx) => (
              <IconContainer
                key={`icon-${iconName}`}
                isSelected={ListOfNativeIcons[idx] === selected}
                onClick={() => {
                  setSelected(ListOfNativeIcons[idx]);
                }}
              >
                {renderIcon(iconName)}
              </IconContainer>
            ))}
          </OuterIconsContainer>
        </ModalBody>
        <ModalFooter>
          <ModalButton
            onClick={() => {
              onChange(selected)
              setIsOpen(false);
            }}
          >
            Save
          </ModalButton>
        </ModalFooter>
      </Modal>
    </div>
  )
};